import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import Wrapper from "../components/layouts/standard-wrapper";
import { useForm } from "react-hook-form";
import { BackButton } from "../components/buttons/buttons";
import { Link } from "gatsby";
import { SubmitButton } from "../components/buttons/buttons";
import Resources from "../components/layouts/resources";
import axios from "axios";
import UserRoute from "../components/routes/userRoute";
import { setProjectData, addSubProject } from "../store/ducks/general";
import CloseIcon from "../images/icons/close.svg";
import youtubeLinks from ".././components/layouts/YoutubeLinks.json";

// Replace with your own data source
import Data from "../data/data";
import { useSelector, useDispatch } from "react-redux";
import ReactModal from "react-modal";
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Header = styled.section``;

const Assets = styled.section`
  margin: 0 0 3rem 0;
  h1 {
    padding: 4.75rem 0 0 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    order: 2;
    h1 {
      padding: 0;
    }
  }
`;
const Checkbox = styled.label`
  display: block;
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
  transform: scale(0.75);
  /* Reset browser default */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create custom checkbox */
  .checkmark {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.border};
    position: absolute;
    inset: 0;
    height: 20px;
    width: 20px;
    transition: all 250ms;
  }
  &:hover input ~ .checkmark {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.hoverBorder};
  }
  input:checked ~ .checkmark {
    background: ${(props) => props.theme.variables.buttons.checkbox.checked};
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.checked};
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Contact = styled.section`
  h2 {
    font-size: 1.875rem;
    font-weight: 500;
    padding: 0 0 0.5rem 0;
    text-align: center;
  }
  form {
    display: grid;
    padding: 0.5rem 0;
    font-size: 0.8125rem;
  }
  .support-question-header {
    color: #dd6327;
    font-weight: 500;
    font-size: 14px;
    padding: 0.5rem 0 2rem 0;
    display: flex;
    justify-content: center;
  }
  .address-field, .inquiry-field {
    grid-column: 1 / span 2;
  }
  .error-message-support {
    color: #ff4d1d;
    font-size: 0.75rem;
    position: relative;
  }
  .submit-button {
    grid-column: 1 / 3;
    margin-top: 1rem;
  }
  span.small {
    font-size: 0.75rem;
  }
  input[type="text"],
  input[type="email"],
  textarea[type="text"] {  
    color: ${(props) => props.theme.colors.primaryColors.white};
      &::placeholder {
        color: ${(props) => props.theme.variables.color};
        background-color: #595959;
      }
      &:focus {
        color: ${(props) => props.theme.colors.secondaryColors.green};
        outline: none !important;
        border-bottom: 1px solid
          ${(props) => props.theme.colors.secondaryColors.green};
      }
    }
  }
  input[type="text"], input[type="email"], textarea[type="text"] {
    transition: all 250ms;
    border: none;
    background: none;
    border-bottom: 1px solid ${(props) => props.theme.variables.form.border};
    margin: 0.5rem 0;
    padding: 16px;
    height: 3rem;
    background-color: #595959;
    font-weight: 500;
    font-size: 14px;
    }
  }
  input[type="text"].name-field {
    margin-right: 1rem;
  }
  textarea[type="text"].inquiry-field {
    height: 100px;
    text-align: left;
  }
  .contact {
    background: ${(props) => props.theme.colors.uiPalette.dark.darkGray};
    border-radius: 3px;
    padding: 1.5rem;
    margin: 1rem 0 0 0;
    a {
      text-decoration: underline;
    }
    p {
      font-size: 0.8125rem;
      text-align: center;
    }
  }
  .hide-contact-form {
    display: none;
  }
  .contact-info {
    padding: 2rem;
    p {
      margin: 0 0 0.75rem 0;
    }
  }
  .contact-preference-container {
    margin: 0.5rem 0 1.5rem 0;
  }
  .contact-preference {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    align-self: flex-start;
    span {
      padding: 0 0 0 0.6rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    order: 1;
    .contact {
      margin: 2rem 0 0 0;
    }
    .contact-info {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.uiPalette.dark.faintGray};
    }
  }
`;

const style = {
  input_field: {
    marginLeft: 10,
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "0.9375rem",
    background: "none",
    border: "none",
    borderBottom: "1px solid #505050",
    padding: "0.3rem",
  },
};

const Support = () => {
  // Translation component
  const { t } = useTranslation();

  // Replace with your preferred function for submitting contact form questions
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [contactFormClassName, setContactFormClassName] = useState("contact");
  const [showMoreGuideCounter, setShowMoreGuideCounter] = useState(1);
  const [shortenedGuideData, setShortenedGuideData] = useState([]);
  const [modalVideoSrc, setModalVideoSrc] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const loginValues = useSelector((state) => state.general.loginValues);
  const subProjectRecoverArr = JSON.parse(localStorage?.getItem("subProjects"));

  let filteredSubProjects;
  if (subProjectRecoverArr) {
  filteredSubProjects = subProjectRecoverArr
    .filter((subProject) => new Date(subProject.date) <= new Date())
    .sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  //Changes show more array everytime counter changes
  useEffect(() => {
    let tempArr = [];

    for (let i = 0; i < showMoreGuideCounter * 4; i++) {
      tempArr.push(youtubeLinks[i]);

      if (i === youtubeLinks.length - 1) {
        break;
      }
    }

    setShortenedGuideData(tempArr);
  }, [showMoreGuideCounter]);

  const setModalProperties = (videoSource) => {
    setModalVideoSrc(videoSource);
    setIsModalOpen(true);
  };

  // useForm Watchers for the contact us form checkboxes
  const isEmailChecked = watch("email_checked", false);

  // Validate if checkbox is clicked that the input isn't empty
  const validateInputWhenCheckbox = (value, check) => {
    if (check && value.length > 0) {
      return true;
    }

    return false;
  };

  // Navigates to the dashboard page
  const goToSubProject = (subProjectData) => {
    dispatch(setProjectData(subProjectData));
    dispatch(addSubProject(subProjectData.sub_project));
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../u-collect-dashboard/dashboard");
  };

  const onSubmit = async (data) => {
    let project;
    let user;

    if (filteredSubProjects.length) {
      project = filteredSubProjects[0].sub_project;
      user = filteredSubProjects[0].title;
    } else {
      project = "n/a";
      user = "n/a";
    }
    await axios.post(`/send-email`, {
      data: {
        userId: loginValues.userId,
        subject: "Contact Request",
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        user_message: data.inquiry,
        sub_project: project,
        user_name: user,
      },
    });

    // Replace with response from server when successfuly submitted
    setIsSubmittedSuccessfully(true);
    setContactFormClassName("contact hide-contact-form");
  };

  return (
    <UserRoute>
      <Layout goToSubProject={goToSubProject}>
        <Wrapper>
          <Header>
            <BackButton />
          </Header>
          <Container>
            <Assets>
              <h1>{t("guides.h1")}</h1>
              <Resources
                resourceData={shortenedGuideData}
                setModalProperties={setModalProperties}
                showMore={
                  youtubeLinks.length < 5 ||
                  showMoreGuideCounter * 4 >= youtubeLinks.length
                    ? false
                    : true
                }
                resourceGrid={false}
                showMoreGuideCounter={showMoreGuideCounter}
                setShowMoreGuideCounter={setShowMoreGuideCounter}
              />
            </Assets>
            <Contact>
              <div className={contactFormClassName}>
                <div className="support-question-header">
                  {t("contact-us.question")}
                </div>
                <h2>{t("contact-us.support")}</h2>
                <p>
                  <Trans
                    i18nKey="contact-us.help"
                    components={{
                      cta: (
                        <a href="https://www.asterra.io" target="_blank"></a>
                      ),
                    }}
                  />
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    {...register("first_name", { required: true })}
                    placeholder={t("contact-us.first-name")}
                    type="text"
                    className="name-field"
                  />
                  <input
                    {...register("last_name", { required: true })}
                    placeholder={t("contact-us.last-name")}
                    type="text"
                    className="last-name-field"
                  />
                  {!errors.first_name ? (
                    <>
                      <span className="error-message-support">{"\u00A0"}</span>
                    </>
                  ) : (
                    <>
                      <span className="error-message-support">
                        {t("error.no-first-name")}
                      </span>
                    </>
                  )}
                  {!errors.last_name ? (
                    <>
                      <span className="error-message-support">{"\u00A0"}</span>
                    </>
                  ) : (
                    <>
                      <span className="error-message-support">
                        {t("error.no-last-name")}
                      </span>
                    </>
                  )}
                  <input
                    {...register("email", { required: true })}
                    placeholder={t("contact-us.email.label")}
                    type="email"
                    className="address-field"
                  />
                  {!errors.email ? (
                    <>
                      <span className="error-message-support">{"\u00A0"}</span>
                    </>
                  ) : (
                    <>
                      <span className="error-message-support">
                        {t("error.no-email")}
                      </span>
                    </>
                  )}
                  <textarea
                    {...register("inquiry", { required: true })}
                    placeholder={t("contact-us.message.placeholder")}
                    type="text"
                    className="inquiry-field"
                  />
                  {!errors.inquiry ? (
                    <>
                      <span className="error-message-support">{"\u00A0"}</span>
                    </>
                  ) : (
                    <>
                      <span className="error-message-support">
                        {t("contact-us.message.error")}
                      </span>
                    </>
                  )}
                  <SubmitButton
                    value={t("contact-us.cta")}
                    className="submit-button"
                  />
                </form>
              </div>
              {isSubmittedSuccessfully && (
                <div className="contact">
                  <h2>{t("contact-us.success.h2")}</h2>
                  <p>{t("contact-us.success.description")}.</p>
                </div>
              )}
            </Contact>
          </Container>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={() => {
              setIsModalOpen(false);
            }}
            className={"modal-content"}
            overlayClassName={"modal-overlay"}
            ariaHideApp={false}
          >
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
              className="close-modal-button"
            >
              <CloseIcon aria-label="button" alt="Close icon" />
            </button>
            <iframe
              height="350vmin"
              width="660vmin"
              src={modalVideoSrc}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="allowfullscreen"
            ></iframe>
          </ReactModal>
        </Wrapper>
      </Layout>
    </UserRoute>
  );
};
export default Support;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "support", "buttons", "navigation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
