import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Arrow from "../../images/icons/arrow.svg";
import youtubeLinks from "./YoutubeLinks.json"

const Container = styled.section`
  position: relative;
  .resource-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
  .resource-title, .videoHeader2 {
    font-size: 1.375rem;
    font-weight: 400;
    margin: 0 0 0.5rem 0;
  }
  .videoHeader2 {
    margin: 2rem 0 3rem 0;
    padding: 0;
    font-size: 30px;
    font-weight: 700;
  }
  .resource-item {
    padding: 1.25rem 0;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.uiPalette.dark.faintGray};
    margin: 1rem 0;
  }
  .call-to-action {
    display: flex;
    justify-content: space-between;
    margin: 0.75rem 0 0.5rem 0;
    color: white;
    font-size: 0.8125rem;
    text-transform: uppercase;
    &:hover {
      path {
        transition: all 250ms;
        stroke: ${(props) => props.theme.colors.secondaryColors.orange};
      }
      line {
        transition: all 250ms;
        stroke: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  .show-more-button, .show-less-button {
    font-size: 0.8125rem;
    font-weight: 500;
    position: absolute;
    inset: auto 0 -2.5rem auto;
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .resource-grid {
      grid-template-columns: 1fr;
    }
  }
`;

const Resources = (props) => {
  // Translation component
  const { t } = useTranslation();

  // Set layout type of resource cards
  const [sectionType, setSectionType] = useState(null);
  useEffect(() => {
    setSectionType(props.resourceGrid ? "resource-grid" : null);
  }, [props.resourceGrid]);

  // Replace with your preferred function to query database for more assets
  const [showMoreGuidesCounter, setShowMoreGuidesCounter] = useState(4)
  const showMore = () => {
    if (showMoreGuidesCounter <= youtubeLinks.length) {
      setShowMoreGuidesCounter(youtubeLinks.length);
    }
  };
  const showLess = () => {
    setShowMoreGuidesCounter(6);
  };

  return (
    <Container>
      <ul className={sectionType}>
      {youtubeLinks.slice(0, props.shortList || showMoreGuidesCounter).map((resourceItem) => {
          return (
            <li key={resourceItem.id} className="resource-item">
                {resourceItem.id === 3 && (
                  <h1 className="videoHeader2">{t("resource.videoHeader2")}</h1>
                )}
              <p className="resource-title">{t(resourceItem.title)}</p>
            
              <button
                onClick={() => {
                  props.setModalProperties(resourceItem.asset.link);
                }}
                className="call-to-action"
                target="_blank"
              >
                <span style={{ marginRight: "20px" }}>
                  {t(resourceItem.asset.title)}
                </span>
                <Arrow />
              </button>
            </li>
          );
        })}
      </ul>
      {props.showMore === false ? null : showMoreGuidesCounter < youtubeLinks.length ? (
        <button className="show-more-button" onClick={showMore}>
          {t("show-more")}
        </button>
        ) : (
          <button className="show-less-button" onClick={showLess}>
            {t("show-less")}
          </button>
        )}
    </Container>
  );
};
export default Resources;
